<template>
  <div>
    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col cols="12" md="7">
          <Card
            borderColor="#e06919"
            class="mb-5"
            title='Os "Loucos pelo Bento"'
            titleColor="#000"
          >
            <template v-slot:conteudo>
              <p>
                No domingo de 30 de julho de 2016, 19 (dezenove) moradores de
                Bento Rodrigues se reuniram na comunidade em ruínas para
                celebrar a Festa de São Bento. O atingido Mauro Marcos da Silva,
                filho do Sr. Filomeno da Silva, decidiu que iria até a
                comunidade para soltar foguetes e rezar em frente às ruínas da
                capela. Conseguiu que outros moradores o acompanhassem e desta
                forma surgiu o “Loucos”, assim considerados pelos que não
                queriam retornar ao local destruído. Em uma atitude de
                manifesto, o grupo adotou o nome “Loucos pelo Bento” e se mantêm
                no enfrentamento para reafirmar a história e as tradições da
                comunidade onde nasceram. Dois anos depois do rompimento da
                barragem, já eram mais de trinta pessoas atuantes na resistência
                e na luta para garantir os direitos dos atingidos.
              </p>
              <p>
                Contrariando as recomendações da Defesa Civil Municipal e da
                Samarco, quanto ao alerta para que as pessoas se afastassem da
                área, os atingidos se mantiveram firmes e protestaram. Tentam
                assim amenizar o sofrimento e manter o vínculo com as tradições
                e o local. Voltam ao território mesmo que destruído quase todos
                os finais de semana para celebração de datas religiosas e até
                mesmo no Carnaval, Ano Novo, Semana Santa. Na última semana de
                setembro os moradores se reúnem em Bento Rodrigues para celebrar
                a Festa de Nossa Senhora das Mercês, que dá nome à capela não
                alcançada pela lama. Retornam sempre que desejam e permanecem
                nas casas situadas na parte mais elevada do subdistrito, onde a
                lama não chegou, em especial na casa da atingida Terezinha
                Custódio Quintão, que morava na edificação que se tornou a sede
                do “Loucos” e faz parte do grupo. A casa foi simbolicamente
                restaurada e a eletricidade vem de um gerador. A água que é
                armazenada no reservatório, é fornecida por um caminhão pipa.
              </p>
            </template>
          </Card>
        </v-col>
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-end align-md-end"
        >
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_os_loucos_01.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Primeira noite em que moradores de Bento decidiram dormir na
            comunidade, em 22/10/16. Fonte: Jornal A Sirene, Ed. 09, dezembro de
            2016.
          </p>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex justify-center align-center">
        <v-col
          cols="12"
          md="5"
          class="d-flex flex-column justify-center align-center justify-md-start align-md-start"
        >
          <v-img
            max-width="100%"
            src="../../../assets/sobre_bento_os_loucos_02.png"
            alt=""
          ></v-img>
          <p
            style="font-size: 0.5em; width:100%; text-align: center; border-radius: 0px 0px 8px 8px;"
            class="pa-2"
          >
            Sede dos “Loucos pelo Bento”. Na foto: Sandro, Simaria, Mônica, Dona
            Maria e Olívia. Foto: Larissa Helena / Jornal A Sirene 2017.
          </p>
        </v-col>
        <v-col cols="12" md="7">
          <Card borderColor="#e06919" class="mb-5">
            <template v-slot:conteudo>
              <p>
                Da mesma forma que a família de Marcus Muniz, a família Quintão
                é uma das principais do grupo, estando sempre presente nos atos.
                Faz parte da família a atingida Sandra, dona do bar que ficava
                ao lado da capela de São Bento, famoso pelas coxinhas preparadas
                por ela. Um dos irmãos de Sandra, Antônio Quintão, quando
                retorna a Bento veste uma calça suja de lama, a mesma que estava
                usando no dia que a barragem rompeu. Nunca mais lavou a calça e
                quando retorna à comunidade faz questão de usá-la.
              </p>
              <p>
                Uma das lutas engajadas pelos “loucos” foi contra a construção
                do Dique S4, que apesar da resistência, foi autorizada pelo
                governador de Minas em 21 de setembro de 2016, alagando grande
                parte de Bento destruída. O decreto assinado deu à mineradora
                Samarco a concessão de uma área de 56 hectares, com validade por
                três anos; no entanto, o prazo para o descomissionamento
                (esvaziamento) não foi cumprido e a comunidade permanece com uma
                grande área alagada.
              </p>
              <p>
                Outra importante pressão feita pelo grupo foi para impedir a
                chamada permuta, ou seja, lutaram para não ceder os terrenos que
                foram destruídos pela lama em troca de uma nova casa, em outro
                local (de reassentamento). Em audiência de conciliação na 2ª
                Vara Cível, Criminal e de Execuções Penais da Comarca de
                Mariana, realizada em 5 de outubro de 2017, a hipótese foi
                descartada. De acordo com o promotor do Ministério Público do
                Estado de Minas Gerais, Guilherme Meneghin, a cessão dos
                terrenos era uma possibilidade levantada por representantes da
                empresa durante as negociações, mas, no acordo assinado, a
                destinação das áreas atingidas será definida pelos moradores.
              </p>

              <p class="pb-0 text-center">
                <strong>Autora: Ana Paula Alves Ferreira<sup>1</sup></strong>
              </p>
            </template>
          </Card>
        </v-col>
      </v-row>
    </section>

    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p>
              <sup>1</sup> Arquiteta e Urbanista pela USP. Assessora Técnica na
              ATI Mariana.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
    <section class="container">
      <v-row class="d-flex">
        <v-col cols="12" class="d-flex flex-column justify-center align-center">
          <div class="inside__card">
            <p><strong>Referências Bibliográficas</strong></p>

            <p>
              CASTRIOTA, Leonardo Barci. Dossiê de Tombamento de Bento
              Rodrigues. UFMG. Belo Horizonte, 2019. Disponível em:
              <a
                target="_blank"
                style="text-decoration: none"
                href="https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento"
                class="pink--text"
                >https://issuu.com/patrimonioculturalmpmg/docs/bentorodriguesdossietombamento</a
              >. Acesso em: 30 mar. 2022.
            </p>
            <p>
              PÓLEN Consultoria Patrimônio e Projetos Ltda. Diagnóstico
              preliminar dos bens culturais identificados no território atingido
              em Mariana pelo rompimento da barragem de Fundão. Mariana, 2017.
            </p>
            <p>
              TEIXEIRA, Vanessa Cerqueira. Fé e cultura barroca sob o manto
              mercedário: devoção, religiosidade e vivências a partir da
              irmandade de Nossa Senhora das Mercês de Mariana (1749-1822). 220
              f. 2017. Dissertação (Mestrado em História) — Instituto de
              Ciências Humanas, Universidade Federal de Juiz de Fora Juiz de
              Fora-MG, 2017.
            </p>
          </div>
        </v-col>
      </v-row>
    </section>
  </div>
</template>

<script>
import Card from "@/components/Card";
export default {
  components: { Card },
  data() {
    return {};
  },
};
</script>

<style scoped>
.router-link-active {
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #e06919;
}
section {
  margin-bottom: 3em;
}

.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
  border-radius: 8px;
}

.inside__card p {
  font-size: 0.5em;
}
</style>
